import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { closeTrailer } from "../../../../modules/program-view";
import Modal from "../../../components/Modal";
import TrailerPlayer from "./player";
import { Close } from "../../../components/Icon";
import IconButton from "../../../components/Button/IconButton";

const StyledModal = styled(Modal)`
  margin: 0 max(16px, 8vw);
  padding: max(16px, 8vh) 0;
  height: 100%;
  display: grid;
  gap: 8px;
  grid-auto-flow: row;
  align-content: center;
`;

const CloseButton = styled(IconButton)`
  color: white;
  width: 24px;
  justify-self: flex-end;
  margin-right: 8px;
  > svg {
    width: 32px;
    height: 32px;
  }
`;
const TrailerModal = () => {
  const dispatch = useDispatch();

  const { isTrailerOpen } = useSelector(state => state.programView);

  const handleClose = useCallback(() => {
    dispatch(closeTrailer());
  }, [dispatch]);

  return (
    <StyledModal
      width="100%"
      background="transparent"
      isOpen={isTrailerOpen}
      onBackgroundClick={handleClose}
      onEscapeKeydown={handleClose}
    >
      <CloseButton id="close-button" Icon={Close} onClick={handleClose} />
      <TrailerPlayer />
    </StyledModal>
  );
};

export default TrailerModal;
